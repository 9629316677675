export const categories = [
  {
    type: '육군',
    specialties: [
      '기술행정병',
      '취업맞춤특기병',
      '지식재산관리병',
      '훈련소조교병',
      '유해발굴감식병',
      '유해발굴기록병',
      '의장병',
      '특전병',
      '탐지분석병',
      '특임군사경찰',
      'MC군사경찰',
      '33경호병',
      '군종병',
      '군악',
      '회계원가비용분석병',
      '화생방시험병',
      '생물학시험병',
      '방사능분석연구보조병',
      '정보보호병',
      '지형자료관리병',
      '신호정보/전자전운용',
      'S/W개발병',
      '드론운용및정비병',
      '영상콘텐츠디자이너',
      '사진콘텐츠디자이너',
      '군사과학기술병',
      'JSA경비병',
      '국군체육특기병',
      '사이버작전병',
      '어학병',
      '카투사',
      '직계가족복무부대병',
      '연고지복무병',
    ],
  },
  {
    type: '해군',
    specialties: [
      '일반기술병',
      '전문기술병',
      '문화홍보병',
      '군악병',
      '특전병',
      '심해잠수병',
      '임기제부사관',
      '취업맞춤특기병',
      '복무지역선택병',
    ],
  },
  {
    type: '공군',
    specialties: ['기술병/전문기술병', '임기제부사관', '취업맞춤특기병'],
  },
  {
    type: '해병',
    specialties: ['기술병', '취업맞춤특기병', '직계가족복무대병'],
  },
];
